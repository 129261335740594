@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.Main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-text {
  padding: .75rem;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-name: slidein;
          animation-name: slidein;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.main-img {
  width: 45%;
}

.main-footer {
  display: flex;
  grid-gap: .5rem;
  gap: .5rem;
  align-items: center;
}

a {
  color: lightblue;
}

a:hover {
  opacity: .75;
}

@-webkit-keyframes slidein {
  from {
    color: #F9F871;
  }

  25% {
    color: #2FAF91;
  }

  50% {
    color: #8CD881;
  }

  75% {
    color: #00818A;
  }

  to {
    color: #F9F871;
  }
}

@keyframes slidein {
  from {
    color: #F9F871;
  }

  25% {
    color: #2FAF91;
  }

  50% {
    color: #8CD881;
  }

  75% {
    color: #00818A;
  }

  to {
    color: #F9F871;
  }
}
html {
    background-color: #7A6563;
}

.intro-page {
    width: 70%;
    margin: 5% auto 0 auto;
    background-color: #ECE2D0;
    border: 7px solid #D3A588;
    border-radius: 25px;
    box-shadow: 10px 10px 12px black;
}

.bioBody {
    color: #7A6563;
    padding: 1rem 4rem;
    font-family: 'Lato', sans-serif;
}

ul {
    -webkit-padding-start: 18px;
            padding-inline-start: 18px;
}

h2, h4 {
    margin-bottom: 0;
}

.disclosure {
    margin-top: 1.5rem;
    color: white;
    text-align: center;
    font-family: 'Lato', sans-serif;
}
