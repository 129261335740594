.App {
  text-align: center;
}

.Main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-text {
  padding: .75rem;
  animation-duration: 5s;
  animation-name: slidein;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.main-img {
  width: 45%;
}

.main-footer {
  display: flex;
  gap: .5rem;
  align-items: center;
}

a {
  color: lightblue;
}

a:hover {
  opacity: .75;
}

@keyframes slidein {
  from {
    color: #F9F871;
  }

  25% {
    color: #2FAF91;
  }

  50% {
    color: #8CD881;
  }

  75% {
    color: #00818A;
  }

  to {
    color: #F9F871;
  }
}